export const useStyles = () => ({
  sectionWrapper: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  sectionSpacing: {
    width: '100%',
  },
})
