import NextLink from 'next/link'
import { useRouter } from 'next/router'
import { useTranslation } from 'next-i18next'

import { getPageSlugFromRouterPath } from '@cms/analytics/utils'
import { showCookieInfoPopup } from '@cms/components/OneTrustCookieManager'
import type { PageFooterProps } from '@components/Layout/Page/Page.types'
import { MiniFooter } from '@knauf-group/ct-designs/components/core/Footer/MiniFooter'
import { WebFooter as Footer } from '@knauf-group/ct-shared-nextjs/components/WebFooter'

export const PageFooter: React.FC<PageFooterProps> = ({
  footerEntries,
  isMiniFooter = false,
}) => {
  const { t } = useTranslation('cms', { keyPrefix: 'common' })

  const router = useRouter()
  const analyticsPageName = getPageSlugFromRouterPath(router)

  const fallbackFooterEntries = {
    socialLinks: [],
    copyright: 'Knauf Digital GmbH',
    legalLinks: [
      {
        id: '6Ba94iBmTqa3ELw5Kdkb1M',
        label: 'Legal Notice',
        href: '/en/legal-pages/legal-notice',
        external: false,
      },
      {
        id: '3I2hQohZ3198BJjKzHQ5gZ',
        label: 'Privacy Policy',
        href: '/en/legal-pages/privacy-policy',
        external: false,
      },
      {
        id: '17KE9zOoX5ICh0tE9Z9fyq',
        label: 'Cookie Policy',
        href: '/en/legal-pages/cookie-policy',
        external: false,
      },
    ],
  }

  const internalFooterEntries = footerEntries ?? fallbackFooterEntries

  const cookieSetting = {
    onClick: showCookieInfoPopup,
    cookieSettingLabel: t('cookies.cookiesSettings', footerEntries ? undefined : { lng: 'en' }),
  }

  return isMiniFooter ? (
    <MiniFooter
      cookieSetting={cookieSetting}
      copyright={internalFooterEntries.copyright}
      legalLinks={internalFooterEntries.legalLinks}
      nextLinkComponent={footerEntries ? NextLink : null}
    />
  ) : (
    <Footer
      {...internalFooterEntries}
      cookieSetting={cookieSetting}
      nextLinkComponent={NextLink}
      languageText={t('languageSelector')}
      analytics={{ eventPayload: { page_name: analyticsPageName } }}
    />
  )
}
