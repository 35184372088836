import { forwardRef } from 'react'
import type { NextRouter } from 'next/router'
import { useRouter } from 'next/router'
import { useTranslation } from 'next-i18next'

import { EXIT_PREVIEW_URL } from '@cms/utils/constants'
import Alert from '@mui/material/Alert'
import AlertTitle from '@mui/material/AlertTitle'
import Button from '@mui/material/Button'
import axios from 'axios'

const EXIT_PREVIEW_ROUTE = '/api/exit-preview'

const onClickExitPreview = async (router: NextRouter) => {
  const req = axios.get(EXIT_PREVIEW_ROUTE)
  const res = await req
  if (res.status === 200) {
    router.push(`${EXIT_PREVIEW_URL}/${router.locale}${router.asPath}`)
  }
}

const PreviewAlert = forwardRef<HTMLDivElement>((_, ref) => {
  const { t } = useTranslation('cms', { keyPrefix: 'common.alerts' })
  const router = useRouter()

  return (
    <Alert
      variant="filled"
      severity="warning"
      sx={{ position: 'sticky', top: 0, zIndex: 1 }}
      action={
        <Button
          variant="outlined"
          color="secondary"
          size="small"
          onClick={() => onClickExitPreview(router)}
        >
          {t('previewAlertExitPreviewButtonLabel')}
        </Button>
      }
      ref={ref}
      data-cy="preview-alert-banner"
    >
      <AlertTitle>{t('previewAlertHeading')}</AlertTitle>
      {t('previewAlertText')}
    </Alert>
  )
})

export default PreviewAlert
