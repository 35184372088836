import type { ReactNode } from 'react'

import type { Styles } from '@knauf-group/ct-designs/utils/types'
import { setSx } from '@knauf-group/ct-designs/utils/utils'
import Box from '@mui/material/Box'
import type { SxProps, Theme } from '@mui/material/styles'

type MainProps = {
  sxStyle?: SxProps<Theme>
  className?: string
  children?: ReactNode
  themeFlag?: boolean
}

const Main: React.FC<MainProps> = ({ children, sxStyle, className }) => {
  const styles: Styles = {
    container: {
      flex: '1',
    },
    main: {
      backgroundColor: 'transparent',
      margin: '0 auto',
      overflow: 'visible',
    },
  }

  return (
    <Box sx={[styles.container, ...setSx(sxStyle)]} className={className}>
      <Box sx={styles.main}>{children}</Box>
    </Box>
  )
}

export default Main
