import {
  NAVIGATION_NO_CATEGORY_LABEL,
  NAVIGATION_NO_TITLE_LABEL,
} from '@knauf-group/ct-shared-nextjs/web/analytics/constants'
import type {
  AnalyticsTargetProps,
  EventPayload,
} from '@knauf-group/ct-shared-nextjs/web/analytics/types'

export const LOGO_ANALYTICS_TARGET: AnalyticsTargetProps = {
  reference: { label: 'logo', href: '/', external: false },
  type: 'link',
}

export const LOGO_CLICK_INITIAL_PAYLOAD: EventPayload = {
  navigation_category: NAVIGATION_NO_CATEGORY_LABEL,
  navigation_title: NAVIGATION_NO_TITLE_LABEL,
  page_section: 'header',
}
