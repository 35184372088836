import { useEffect, useMemo, useRef, useState } from 'react'
import dynamic from 'next/dynamic'
import NextLink from 'next/link'
import { useRouter } from 'next/router'

import { LOGO_ANALYTICS_TARGET, LOGO_CLICK_INITIAL_PAYLOAD } from '@cms/analytics/constants'
import { getPageSlugFromRouterPath } from '@cms/analytics/utils'
import PreviewAlert from '@cms/components/PreviewAlert'
import { useGlobalContext } from '@cms/hooks/useGlobalContext'
import { DEFAULT_LOCALE } from '@cms/middleware/constants'
import { MiniHeader } from '@knauf-group/ct-designs/components/core/MiniHeader'
import { NextComponentContext } from '@knauf-group/ct-designs/components/core/WebHeader'
import { getLanguageAndCountryCodes } from '@knauf-group/ct-shared-nextjs/client'
import { AuthComponent } from '@knauf-group/ct-shared-nextjs/components/WebHeader2/DesktopAuthComponent/AuthComponent'
import { SearchComponent } from '@knauf-group/ct-shared-nextjs/components/WebHeader2/SearchComponent/SearchComponent'
import { WebHeaderProvider } from '@knauf-group/ct-shared-nextjs/components/WebHeader2/utils/WebHeaderProvider'
import { useClickEvent, useReferenceWithOnClick } from '@knauf-group/ct-shared-nextjs/web/hooks'
import { getNavLinksWithAnalytics } from '@knauf-group/ct-shared-nextjs/web/utils/navigation'
import type { Theme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useFlag } from '@unleash/nextjs'

import type { PageHeaderProps, WebHeaderProps } from './Page.types'

// dynamic imports only works consumer side
const DynamicSearchLogicComponent = dynamic(
  () =>
    import(
      '@knauf-group/ct-shared-nextjs/components/WebHeader2/SearchComponent/SearchLogicComponent'
    ).then((m) => m.SearchLogicComponent),
  {
    ssr: false,
  },
)

// dynamic imports only works consumer side
const DynamicMobileHeader = dynamic(() =>
  import('@knauf-group/ct-shared-nextjs/components/WebHeader2/MobileHeader/MobileHeader').then(
    (m) => m.MobileHeader,
  ),
)

// dynamic imports only works consumer side
const DynamicDesktopHeader = dynamic(() =>
  import(
    '@knauf-group/ct-shared-nextjs/components/WebHeader2/DesktopHeader/DesktopHeader'
  ).then((m) => m.DesktopHeader),
)

// dynamic imports only works consumer side
const DynamicDesktopLoggedSection = dynamic(
  () =>
    import(
      '@knauf-group/ct-shared-nextjs/components/WebHeader2/DesktopAuthComponent/LoggedSection'
    ).then((m) => m.LoggedSection),
  {
    ssr: false,
  },
)

// dynamic imports only works consumer side
const DynamicMobileAuthComponents = dynamic(
  () =>
    import(
      '@knauf-group/ct-shared-nextjs/components/WebHeader2/MobileAuthComponents/MobileAuthComponents'
    ).then((m) => m.MobileAuthComponents),
  { ssr: false },
)

// dynamic imports only works consumer side
const DynamicMobileDrawerFooter = dynamic(
  () =>
    import(
      '@knauf-group/ct-shared-nextjs/components/WebHeader2/MobileHeader/MobileDrawerFooter'
    ).then((m) => m.MobileDrawerFooter),
  { ssr: false },
)

export const WebHeader = ({
  analytics,
  appsConfig,
  onClickLogo,
  customAppBarSx,
  ...restProps
}: WebHeaderProps) => {
  const router = useRouter()

  // TODO: refactor getLanguageAndCountryCodes to always return countryCode
  const { countryCode } = getLanguageAndCountryCodes(router.locale ?? '')
  const searchEnabled = useFlag(`gs-${countryCode?.toLowerCase()}`)
  const authEnabled = useFlag(`login-${countryCode?.toLowerCase()}`)

  const isDesktop = useMediaQuery<Theme>((theme) => theme.breakpoints.up('lg'))

  const defaultOnClickLogo = () => router.push('/')

  // add analytics to the navigation links
  const { getReferenceWithOnClick } = useReferenceWithOnClick()
  const navigationLinks = analytics
    ? getNavLinksWithAnalytics(restProps.navigationLinks, {
        ...analytics,
        getReferenceWithOnClick,
      })
    : restProps.navigationLinks

  const nextComponents = useMemo(
    () => ({
      nextLinkComponent: NextLink,
      nextRouter: router,
    }),
    [router],
  )

  return (
    <NextComponentContext.Provider value={nextComponents}>
      <WebHeaderProvider
        appsConfig={appsConfig}
        navigationLinks={navigationLinks}
        onClickLogo={onClickLogo ?? defaultOnClickLogo}
        slotProps={{ AppBarProps: { sx: customAppBarSx } }}
      >
        {isDesktop ? (
          <DynamicDesktopHeader
            data-cy="header"
            {...(searchEnabled && {
              SearchComponent: (
                <SearchComponent DynamicSearchLogicComponent={DynamicSearchLogicComponent} />
              ),
            })}
            {...(authEnabled && {
              AuthComponent: (
                <AuthComponent DynamicLoggedSection={DynamicDesktopLoggedSection} />
              ),
            })}
          />
        ) : (
          <DynamicMobileHeader
            {...(authEnabled && {
              DynamicMobileAuthComponents,
              DynamicMobileDrawerFooter,
            })}
            {...(searchEnabled && {
              SearchComponent: (
                <SearchComponent DynamicSearchLogicComponent={DynamicSearchLogicComponent} />
              ),
            })}
          />
        )}
      </WebHeaderProvider>
    </NextComponentContext.Provider>
  )
}

export const PageHeader: React.FC<PageHeaderProps> = ({
  isPageInPreviewMode,
  headerEntries,
  appsConfig,
  isMiniHeader = false,
}) => {
  const router = useRouter()
  const { push: pushClickEvent } = useClickEvent()
  const { headerHeightWithOffsetTop, setHeaderHeightWithOffsetTop } = useGlobalContext()

  const { locale } = router
  const previewComponentRef = useRef<HTMLDivElement>(null)
  const [headerOffsetTopValue, setHeaderOffsetTopValue] = useState(0)

  const analyticsPageName = getPageSlugFromRouterPath(router)

  const onClickLogo = () => {
    pushClickEvent?.({
      ...LOGO_ANALYTICS_TARGET,
      eventPayload: {
        ...LOGO_CLICK_INITIAL_PAYLOAD,
        page_name: analyticsPageName,
      },
    })
    const targetRoute = locale === DEFAULT_LOCALE ? '/en' : '/'
    router.push(targetRoute)
  }

  useEffect(() => {
    // this is to set the header position(which will then become its offsetTop value) when preview banner is present
    function updateHeaderPosition() {
      if (previewComponentRef.current) {
        const previewComponentHeight = previewComponentRef.current.clientHeight
        setHeaderOffsetTopValue(previewComponentHeight)
      } else {
        setHeaderOffsetTopValue(0)
      }
    }

    window.addEventListener('resize', updateHeaderPosition)
    updateHeaderPosition()
    return () => window.removeEventListener('resize', updateHeaderPosition)
  }, [isPageInPreviewMode])

  useEffect(() => {
    function updateHeightWithOffsetTop() {
      const headerElement = document.getElementsByTagName('header')?.[0]

      if (headerElement && headerHeightWithOffsetTop === 0) {
        // this renders first and here headerOffsetTopValue is still undefined
        setHeaderHeightWithOffsetTop(headerElement.clientHeight)
      }

      if (headerOffsetTopValue) {
        // once headerOffsetTopValue is available, we append its value on top of header element height
        setHeaderHeightWithOffsetTop(headerHeightWithOffsetTop + headerOffsetTopValue)
      }
    }

    window.addEventListener('resize', updateHeightWithOffsetTop)
    updateHeightWithOffsetTop()
    return () => window.removeEventListener('resize', updateHeightWithOffsetTop)
    // we don't want to add headerHeightWithOffsetTop as dependency to avoid unnecessary rerenders
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [headerOffsetTopValue, setHeaderHeightWithOffsetTop])

  return (
    <>
      {isPageInPreviewMode && <PreviewAlert ref={previewComponentRef} />}
      {isMiniHeader ? (
        <MiniHeader
          onLogoClick={onClickLogo}
          sx={{
            top: headerOffsetTopValue,
          }}
        />
      ) : (
        <WebHeader
          appsConfig={appsConfig}
          navigationLinks={headerEntries}
          analytics={{ eventPayload: { page_name: analyticsPageName } }}
          onClickLogo={onClickLogo}
          customAppBarSx={{
            top: headerOffsetTopValue,
          }}
        />
      )}
    </>
  )
}
